/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Work Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Work Sans Regular'), url('WorkSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Work Sans Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Work Sans Thin'), url('WorkSans-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Work Sans ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Work Sans ExtraLight'),
    url('WorkSans-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Work Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local('Work Sans Light'), url('WorkSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'Work Sans Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Work Sans Medium'), url('WorkSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Work Sans SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Work Sans SemiBold'), url('WorkSans-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Work Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Work Sans Bold'), url('WorkSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Work Sans ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Work Sans ExtraBold'),
    url('WorkSans-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Work Sans Black';
  font-style: normal;
  font-weight: normal;
  src: local('Work Sans Black'), url('WorkSans-Black.woff') format('woff');
}
